import { styled } from "styled-components";
import { Tabs, Tab } from "react-bootstrap";

export const NavButton = styled.button`

`;

export const FixedTabs = styled.div`

  background-color: ${props =>
    `rgba(79, 79, 79, ${props.$opacity})`
  };
  transform: translateY(0);
  transition: transform 0.5s ease;

  &.down {
    transform: translateY(-100%);
  }

  position: fixed;
  text-align: center;
  border: none;
  left: 0;
  z-index: 99;
  max-width: 100vw;
  overflow-x: hidden;

.nav-link {
  border: none;
  background: none;
  color: #fff;
  font-size: 1.125rem;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  padding: 0;
  font-family: 'Eastman', 'Arial Narrow Bold', sans-serif;
}
.nav-link.active span {
  border-bottom: 2px solid #fff;
  padding: 0.5rem;
}
@media (max-width: 768px) {
  .nav-link {
    font-size: 1rem;
  }
}



  /* position: fixed;
  z-index: 99;
  border: none;
  justify-content: center;
  width: 100%;
  display: flex;

  .tab-content {
    padding: 0;
  }
  .nav-link,
  .nav-item.show .nav-link, 
  .nav-link.active {
    color: #fff;
    border: none;
    text-transform: uppercase;
    background: none;
    font-weight: 900;
    border-bottom: 3px solid transparent;
  }

  .nav-item.show .nav-link, 
  .nav-link.active {
    border-bottom-color: #fff;
  } */
`;

export const FixedTab = styled(Tab)`
  padding: 0;

`