import { styled } from "styled-components";
import { motion } from "framer-motion";
import Overlay from "../Overlay";

export const FixedContainer = styled.section`
  /* position: fixed;
  min-height: 100vh;
  width: 100%; */
`;

export const HeroOuter = styled(motion.div)`
  overflow: hidden;
`;

export const HeroWrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-position: center; */
  /* background-image: ${props =>
    `url(${props.desktopBackground})`
  }; */
  /* background-size: cover; */

  overflow: hidden;
  /* clip-path: circle(100% at center); */

  -webkit-mask-image: url("img/circle.svg");
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-image: url("img/circle.svg");
  mask-repeat: no-repeat;
  mask-position: center center;
  mask-composite: add;

  @media (orientation: portrait) {
    /* background-image: ${props =>
    `url(${props.mobileBackground})`
  }; */
  }
`;

export const HeroBackground = styled(Overlay)`
  background-position: center;
  background-image: ${props =>
    `url(${props.desktopBackground})`
  };

  @media (orientation: portrait) {
    background-image: ${props =>
    `url(${props.mobileBackground})`
  };
  }

`;