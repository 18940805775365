import { styled } from "styled-components";
import { motion } from "framer-motion";


export const OverlayWrapper = styled(motion.div)`

  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: ${props => `${props.z}`};

  > * {
    position: absolute;
    width: 100%;
  }

  .overlay-x-left {
    left: 0;
  }

  .overlay-x-center {
    left: 50%;
    transform: translateX(-50%);
  }

  .overlay-x-right {
    right: 0;
  }

  .overlay-y-top {
    top: 0;
  }

  .overlay-y-center {
    top: 50%;
    transform: translateY(-50%);
  }

  .overlay-y-bottom {
    bottom: 0;
  }

  .overlay-y-center.overlay-x-center {
    transform: translate(-50%, -50%);
  }

  .overlay-x-cover {
    width: 100%;
  }
  .overlay-y-cover {
    height: 100%;
  }

  .overlay-y-cover.overlay-x-cover {
    top: 0;
    left: 0;
  }

`;
