
import { Container, Row, Col } from "react-bootstrap";
import { SessionData, Deadlines } from "../../constants";
import Hero from "../Hero";
import QuoteReveal from "../QuoteReveal";
import Test from "../QuoteReveal/Test.js";
import ParallaxSection from "../ParallaxSection";
import GenericContentContainer from "../GenericContentContainer";
import { FadeInLeft, FadeInScaleUpRotate, RotateScaleIn } from "../../constants";
import Overlay from "../../components/Overlay";
import { motion } from "framer-motion";

const Home = () => {



  return (
    <div>
      <Hero
        text="Your Buyer Is Out There."
        desktopBackground={"img/hero-bg-6.jpg"}
        mobileBackground={"img/mobile-hero-bg-6.jpg"}
        animated
      >

        <Overlay x={"cover"} y={"bottom"}>
          <Container fluid className="mb-3 mb-md-5 pb-md-2 text-white">
            <Row className="mt-lg-5">
              <Col xs={12} md={6}>
                <div
                  className="ms-3 ms-lg-5 mb-4 mb-md-0"
                  variants={FadeInLeft}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.75, delay: 2.5 }}
                >
                  <h2 className="h3">An international<br />
                    audience for your<br />
                    breeding stock.<br />
                    <a className="text-orange" href="https://flex.keeneland.com/onlineentries/LandingPage.html">Find it here.</a></h2>
                </div>
              </Col>
              <Col xs={12} md={6} className="text-md-end">
                <div
                  className="ms-3 ms-md-0 me-md-3 me-lg-5"
                  variants={FadeInLeft}
                  initial="hidden"
                  animate="visible"
                  transition={{ duration: 0.75, delay: 3.5 }}
                >
                  <h2 className="h3">Keeneland November<br />Breeding Stock Sale</h2>
                  <p>November 5 - November 13</p>
                </div>
              </Col>
            </Row>
          </Container>
        </Overlay>

      </Hero>
      {/* 
      <div className='issuu-embed-container'>

        <iframe src="//e.issuu.com/embed.html?pageLayout=1&viewMode=presentation#27050934/41378144" frameborder='0' allowfullscreen></iframe>

      </div> */}

      {/* 
      <ParallaxSection>
        <Container fluid className="text-white">
          <Row className="justify-content-center px-3">
            <Col lg={10}>
              <Row>
                <Col lg={9}>
                  <h2>Sale Schedule</h2>
                  <p>Session start times are estimated based on predicted session count. Sale end date and number of books are dependednt on final number of entries.</p>
                </Col>
              </Row>
              <Row className="mt-3">
                {
                  SessionData.map((session, i) => {
                    return (
                      <Col xs={12} md={6} lg={4} className="py-4" key={"session-data-" + i}>
                        <h3>{session.title}</h3>
                        <p className="text-uppercase my-0"><strong>{session.subtitle}</strong></p>
                        <p><em>{session.description}</em></p>
                      </Col>
                    );
                  })
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </ParallaxSection> */}

      <div className="bg-beige">
        <GenericContentContainer>
          <h2 className="my-5">2024 Deadlines</h2>
          <Row className="justify-content-end">
            {
              Deadlines.map((deadline, i) => {
                return (
                  <Col xs={12} md={6} lg={4} className="mt-4 mb-3 my-md-5 pe-5" key={"deadline-data-" + i}>
                    <h3><div className="text-uppercase p"><strong>{deadline.subtitle}</strong></div>{deadline.title}</h3>
                    <hr />
                    <p dangerouslySetInnerHTML={{ __html: deadline.description }}></p>
                  </Col>
                )
              })
            }
            <Col xs={12} md={6} lg={4} className="my-5 pe-5">
              <p><em>*All broodmares or broodmare prospects must have a veterinary certificate on file stating "pregnant" or "suitable for mating." Veterinary certificates must be dated within 10 days of selling date.<br /><br />
                Health certificates, including EHV-1 vaccine information, must be dated within 30 days of arrival on sale grounds and are required in our office prior to sell.</em></p>
            </Col>
          </Row>
        </GenericContentContainer>
      </div>

      <div className="d-none d-md-block">
        <QuoteReveal
          text={"It's the cornerstone of what we do in this business. It's literally where the foundation is laid. You can come in, you can select from what you want - from maiden mares to proven mares to mares that are in foal, carrying however number of their foals. It's what everybody looks forward to. There would be no Keeneland September if there wasn't a Keeneland November."}
          attribution={"Jacob West on Keeneland November"}
          background={"img/quote-bg-10.jpg"}
        />
      </div>

      <div className="d-md-none">
        <QuoteReveal
          text={"It's the cornerstone of what we do in this business. It's literally where the foundation is laid. You can come in, you can select from what you want - from maiden mares to proven mares to mares that are in foal, carrying however number of their foals. It's what everybody looks forward to. There would be no Keeneland September if there wasn't a Keeneland November."}
          attribution={"Jacob West on Keeneland November"}
          background={"img/quote-bg-mobile-3.jpg"}
        />
      </div>

    </div>
  );
}

export default Home;