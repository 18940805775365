import { React } from "react";
import "./fonts/eastman/Eastman-Condensed-Extrabold.ttf";
import "./fonts/eastman/Eastman-Condensed-Extrabold.otf";
import "./fonts/eastman/Eastman-Condensed-Extrabold.woff";
import "./fonts/eastman/Eastman-Condensed-Extrabold.woff2";
import TagManager from "react-gtm-module";
import Lenis from "lenis";
import AnimatedCursor from "react-animated-cursor"
import FixedNavbar from "./components/FixedNavbar";
import './App.css';
import './Utilities.css';

const transition = { duration: 1, yoyo: 5, ease: "easeInOut" };

function App() {

  const tagManagerArgs = {
    gtmId: 'GTM-WVZZ29T'
  }

  TagManager.initialize(tagManagerArgs)

  const lenis = new Lenis();

  lenis.on('scroll', (e) => {

  })

  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)

  return (
    <div>
      <AnimatedCursor
        color="255, 255, 255"
        innerSize={12}
        outerSize={25}
        innerScale={4}
        outerScale={2.7}
        outerAlpha={0}
        outerStyle={{
          mixBlendMode: 'exclusion'
        }}
        innerStyle={{
          mixBlendMode: 'exclusion'
        }}
      />

      <FixedNavbar />

    </div>
  );
}

export default App;
