import { Container, Row, Col } from "react-bootstrap";
import { Spacer } from "./styles";


const GenericContentContainer = ({className, children}) => {

  return (
    <div className={className}>
      <Spacer>
        <Container fluid>
          <Row className="justify-content-center px-3">
            <Col xs={12} lg={10}>
              {children}
            </Col>
          </Row>
        </Container>
      </Spacer>
    </div>
  )
}

export default GenericContentContainer;