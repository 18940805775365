import { styled } from "styled-components";
import { motion } from "framer-motion";

export const ParallaxWrapper = styled(motion.section)`

  overflow: hidden;
  position: relative;
  display: block;
`;

export const ParallaxContentWrapper = styled(motion.div)`
  padding: ${props => 
    `${props.offset / 3}px 0`
  };
  z-index: 49;
  position: relative;
`;

export const ParallaxBackground = styled(motion.div)`
  top: ${props =>
    `-${props.offset * 2}px`
  };
  left: 0;
  position: absolute;
  width: 150%;
  height: ${props =>
    `calc(100% + ${props.offset}px)`
  };
  left: -40%;
  border-radius: 0 0 50% 50%;
  @media (max-width: 1600px) {
    width: 250%;
    left: -100%;
  }
  @media (max-width: 720px) {
    width: 400%;
    left: -200%;
  }
`;