import { OverlayWrapper } from "./styles";

const Overlay = ({x, y, z="auto", style, variants, initial, animate, children}) => {

  return (
    <OverlayWrapper
      initial={initial}
      animate={animate}
      variants={variants}
    >
      <div className={"overlay-x-" + x + " overlay-y-" + y}
        style={style}
      >
        {children}
      </div>
    </OverlayWrapper>
  )

}


export default Overlay;