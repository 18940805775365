import { useRef } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from "framer-motion";
import { ScaleInFromTop } from "../../constants";
import { QuoteRevealWrapper, BlockQuoteWrapper, BlockQuoteBG, BlockQuoteBorder, BlockQuote } from "./styles";




const QuoteReveal = ({text, attribution, background}) => {

  const quoteRef = useRef(null);
  const quoteIsInView = useInView(quoteRef, { once: true, amount: 0.5});
  

  return (
    <QuoteRevealWrapper
      style={{backgroundImage: `url("${background}")`}}
    >
      <BlockQuoteWrapper>
        <BlockQuoteBG
          className="bg-gray"
          variants={ScaleInFromTop}
          initial="start"
          animate={quoteIsInView && "end"}
        />
        <BlockQuoteBorder />
        <Container fluid>
          <Row>
            <Col lg={{offset: 1, span: 8}} xs={{offset: 1, span: 10}}>
              <BlockQuote
                ref={quoteRef}
              >
                <p className="text-white">{text}</p>
                <footer
                  className="text-orange"
                >{attribution}</footer>
              </BlockQuote>
            </Col>
          </Row>
        </Container>
      </BlockQuoteWrapper>

    </QuoteRevealWrapper>
  );

}

export default QuoteReveal;