export const productionRoot = "https://november.keeneland.com";

export const FadeInLeft = {
  visible: {
    opacity: 1,
    x: 0
  },
  hidden: {
    opacity: 0,
    x: -100
  }
};



export const FadeInScaleUpRotate = {
  visible: {
    opacity: 1,
    scale: 1,
    rotate: 0,
    transition: {
      duration: 2,
      ease: [.17, .67, .42, .97]
    }
  },
  hidden: {
    opacity: 0,
    scale: 1,
    width: "100%",
    rotate: 45
  }
}



export const RotateScaleIn = {
  start: {
    scale: 0.25,
    rotate: -45,
  },
  end: {
    scale: 1,
    rotate: 0,
    transition: {
      duration: 1.25,
      ease: [.68, .01, .22, .96]
    }
  }
}



export const BackgroundRotateScaleIn = {
  start: {
    backgroundSize: 0
  },
  end: {
    backgroundSize: "100%",
    transition: {
      duration: 2,
      ease: [.68, .01, .22, .96]
    }
  }
}



export const MaskScaleIn = {
  start: {
    maskSize: "0px"
  },
  end: {

  }
};



export const ScaleInFromTop = {
  start: {
    y: "-100%"
  },
  end: {
    y: "0",
    transition: {
      duration: 1.25,
      ease: [.02, .62, .14, .99]
    }
  }
}






export const SessionData = [
  {
    "title": "Book 1",
    "subtitle": "Monday, Sept. 9 - Tuesday, Sept. 10",
    "description": "Brunch at 11 a.m., sessions begin at 1 p.m."
  },
  {
    "title": "Book 2",
    "subtitle": "Wednesday, Sept. 11 - Tuesday, Sept. 12",
    "description": "Sessions begin at 11 a.m."
  },
  {
    "title": "Dark Day",
    "subtitle": "Friday, Sept. 13",
    "description": ""
  },
  {
    "title": "Book 3",
    "subtitle": "Saturday, Sept. 14 - Sunday, Sept. 15",
    "description": "Sessions begin at 10 a.m."
  },
  {
    "title": "Book 4",
    "subtitle": "Saturday, Sept. 14 - Sunday, Sept. 15",
    "description": "Sessions begin at 10 a.m."
  },
  {
    "title": "Book 5",
    "subtitle": "Saturday, Sept. 16 - Sunday, Sept. 17",
    "description": "Sessions begin at 10 a.m."
  },
  {
    "title": "Book 6",
    "subtitle": "Saturday, Sept. 18 - Sunday, Sept. 19",
    "description": "Sessions begin at 10 a.m."
  },
  {
    "title": "Book 7",
    "subtitle": "Saturday, Sept. 20 - Sunday, Sept. 21",
    "description": "Sessions begin at 10 a.m."
  }
];


export const Deadlines = [
  {
    "title": "August 1",
    "subtitle": "Thursday",
    "description": "Entries due online"
  },
  {
    "title": "August 7",
    "subtitle": "Wednesday",
    "description": "Property Line Changes"
  },
  {
    "title": "August 11",
    "subtitle": "Sunday",
    "description": "Racing updates (races that run after this date will not appear in catalog)"
  },
  {
    "title": "August 12",
    "subtitle": "Monday",
    "description": "Breeders' Cup, EBF, and state-bred nominations due into their respective offices in order to appear in the sales catalog"
  },
  {
    "title": "August 16",
    "subtitle": "Friday",
    "description": "Final withdrawal from sale for horses not to appear in catalog (second $500 entry fee waived)<br/>Wish lists due"
  },
  {
    "title": "August 21",
    "subtitle": "Wednesday",
    "description": "The Jockey Club Registration certificates due for all except weanlings ($100 penalty per horse will apply after this date)"
  },
  {
    "title": "August 30",
    "subtitle": "Friday",
    "description": "NTRA contribution lists<br/>Preliminary placements released"
  },
  {
    "title": "September 2",
    "subtitle": "Monday",
    "description": "Black-type racing updated through this date for catalog"
  },
  {
    "title": "September 3",
    "subtitle": "Tuesday",
    "description": "Deadline to request session swaps<br/>First $500 of Entry Fee"
  },
  {
    "title": "September 4",
    "subtitle": "Wednesday",
    "description": "Proof page changes<br/>Consignors’ client lists for catalog mailing"
  },
  {
    "title": "October 1",
    "subtitle": "Tuesday",
    "description": "Original Coggins certificates ($100 penalty per horse will apply after this date)<br/>EVA results dated within 90 days of horses' sale date. Recommended blood pull Aug. 19 - Sept. 4, 2024 ($100 penalty per horse will apply after this date)<br/>The Jockey Club Registration certificates for all weanlings($100 penalty per horse will apply after this date)<br/>Stallion service certificates($100 penalty per horse will apply after this date)<br/>Consignor’s Authorized Agent form($100 penalty per property line will apply after this date)"
  },
  {
    "title": "October 15",
    "subtitle": "Tuesday",
    "description": "X-rays to Repository: Must be taken within 21 days of sale date.<br/>X- rays, endoscopic videos and veterinary certificates must be on file in the Repository at least four days prior to sale date."
  }
];



export const Weanlings = [
  {
    "amount": "$750,000",
    "content": "Gun Runner<br/>Urban Hill (GB) colt"
  },
  {
    "amount": "$600,000",
    "content": "Into Mischief<br/>Blame It On Alpha filly"
  },
  {
    "amount": "$600,000",
    "content": "Curlin<br/>Eileen's Dream colt"
  },
  {
    "amount": "$550,000",
    "content": "Curlin<br/>Tin Type Gal filly"
  },
  {
    "amount": "$525,000",
    "content": "Justify<br/Thundering Emilia colt"
  }
];