import { useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useInView } from "framer-motion";
import { ScaleInFromTop } from "../../constants";
import { QuoteRevealWrapper, BlockQuoteWrapper, BlockQuoteBG, BlockQuoteBorder, BlockQuote } from "./styles";
import {styled } from "styled-components";
import {motion} from "framer-motion";

const Wrap = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  padding: 10vh 10vw;
`;


function Section({ setBg, bg, children }) {
  const ref = useRef(null);
  const isInView = useInView(ref);

  useEffect(() => {
    if (isInView) {
      setBg(bg);
    }
  }, [isInView])

  return (
    <section ref={ref}>
      <span
        style={{
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: "all 0.9s cubic-bezier(0.17, 0.55, 0.55, 1) 0.5s"
        }}
      >
        {children}
      </span>
    </section>
  );
}

const Test = ({text, attribution, background}) => {

  const quoteRef = useRef(null);
  const quoteIsInView = useInView(quoteRef, { amount: 0.5});



  const sections = [
    {
      text: "IT'S THE CORNERSTONE OF WHAT WE DO IN THIS BUSINESS. IT'S LITERALLY WHERE THE FOUNDATION IS LAID. YOU CAN COME IN, YOU CAN SELECT FROM WHAT YOU WANT - FROM MAIDEN MARES TO PROVEN MARES TO MARES THAT ARE IN FOAL, CARRYING HOWEVER NUMBER OF THEIR FOALS. IT'S WHAT EVERYBODY LOOKS FORWARD TO. THERE WOULD BE NO KEENELAND SEPTEMBER IF THERE WASN'T A KEENELAND NOVEMBER.",
      bg: "#ff0000",
      img: "img/quote-bg-3.jpg"
    },
    {
      text: `

Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vel purus at ex interdum finibus. Mauris gravida tellus risus, vel molestie justo iaculis in. Maecenas euismod justo in pretium fermentum. In sodales tincidunt lacus vel ultricies. Etiam cursus augue nisi, convallis ullamcorper ex bibendum semper. Etiam venenatis ante libero, vitae ultrices enim rutrum at. Ut interdum, dolor quis egestas pellentesque, lorem nulla venenatis erat, in placerat odio est non sem. Aliquam blandit bibendum lorem et pretium. Curabitur lobortis mollis dolor. Nullam et augue justo. Proin ut fermentum odio. Phasellus pulvinar augue sit amet ipsum tempor eleifend. Sed semper pulvinar elit at condimentum.

Nullam consectetur euismod nibh, ac aliquet dui tempus consequat. Nunc efficitur quam eget purus efficitur pharetra. Donec accumsan arcu ut dolor hendrerit tincidunt. Donec facilisis, nunc id varius blandit, diam diam auctor velit, sit amet pellentesque lacus lorem sit amet turpis. Suspendisse quis aliquet felis. Vestibulum varius aliquet mattis. Praesent aliquet mattis leo. Proin vitae tempor magna. Quisque commodo orci non dui euismod scelerisque. Mauris feugiat viverra metus, eu laoreet ante eleifend non. Sed eu tortor diam. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Etiam dictum neque mauris, nec vestibulum turpis pharetra quis. Sed dignissim aliquam ultricies. Nullam porttitor augue sit amet libero iaculis rutrum.

Aliquam erat volutpat. Duis sit amet quam tortor. Mauris rutrum augue turpis, ac tincidunt metus pharetra in. Sed vitae iaculis tellus. Ut sit amet ex quis neque sagittis pharetra eget quis libero. Praesent at nunc et metus sollicitudin pharetra in a erat. Nunc vitae vulputate tortor. Nunc feugiat efficitur lorem in consequat.

Sed diam nisi, pulvinar non ante eget, faucibus ultrices turpis. Proin egestas eros et dignissim consequat. Maecenas sit amet erat est. Suspendisse ut euismod dui, eget pellentesque neque. Aenean in quam non mi hendrerit dapibus. Suspendisse purus metus, tristique et ligula eget, aliquam vehicula velit. Nullam purus sapien, dictum volutpat bibendum id, fringilla non urna. Cras suscipit vulputate sapien a congue. Aliquam tempor vitae leo ac iaculis. Phasellus id sapien ac neque condimentum dignissim sed ut tortor.

Proin malesuada fringilla libero sed vulputate. Nunc ut venenatis est, et feugiat risus. Vivamus hendrerit ac dolor ac viverra. Nam eu nisl scelerisque, dapibus mi vel, fermentum justo. Donec sit amet eros velit. Sed eu lobortis nisi, et pulvinar nibh. Integer efficitur justo leo, at sollicitudin odio luctus ac. Proin ullamcorper neque ut lacus mattis volutpat sit amet eu purus. Quisque semper ante sed ex efficitur, sed fringilla erat cursus. In eleifend ligula sem, a ullamcorper mauris bibendum vel. Mauris accumsan lectus a lorem iaculis feugiat. Ut vel faucibus mi, id sollicitudin enim. Morbi ac metus nec ex sollicitudin ultrices non scelerisque nunc. Duis congue, nibh sit amet sagittis fermentum, ante felis tincidunt mauris, vel suscipit ipsum lorem vitae felis. Proin vel viverra nulla. Aenean facilisis sagittis diam quis porta. `,
      bg: "#00ff00",
      img: "img/quote-bg-2.jpg"
    },
    {
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit.Cras consequat sit amet orci cursus volutpat.Sed pellentesque, lacus in ullamcorper pellentesque, arcu ante commodo urna, vel feugiat felis ligula quis turpis.Praesent in dui ultricies, interdum nunc quis, laoreet lectus.Vestibulum vitae enim sed lorem dapibus lobortis.Sed id est vitae tortor consequat tincidunt.Etiam ornare diam vestibulum odio commodo, efficitur dictum enim elementum.Phasellus ultricies tempus erat sit amet volutpat.Donec pellentesque purus a laoreet sodales.Maecenas pharetra consequat justo, in fringilla urna dignissim ac.

Nulla dictum mi vehicula bibendum aliquet.Vivamus sagittis urna eget leo congue, tincidunt pretium ante tempor.Pellentesque posuere libero non lacinia mollis.Suspendisse sed nibh aliquet, tempor purus sit amet, scelerisque velit.Phasellus id augue turpis.Duis pretium malesuada sapien ac molestie.Aliquam dictum volutpat leo ut suscipit.Cras non leo a diam volutpat fringilla.

Sed iaculis tincidunt suscipit.Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Mauris id dui vulputate, dapibus urna eget, dignissim justo.Maecenas at sem at mauris vehicula vestibulum.Maecenas molestie, nulla ac molestie vehicula, ligula magna lacinia justo, vitae efficitur diam ante eu velit.Cras tempus rhoncus est, et elementum turpis faucibus vitae.Praesent pharetra dapibus eros, vitae auctor justo lobortis ut.Mauris dignissim aliquam mauris eu dictum.Proin vestibulum sit amet lorem vitae varius.

Sed quis cursus velit, non blandit purus.Curabitur pellentesque neque ut leo ultrices feugiat.Pellentesque id lectus id leo placerat ultrices et id est.Praesent facilisis dolor et neque placerat scelerisque.Ut ultricies diam ut sodales ultrices.Sed at aliquam quam.Cras eu condimentum tellus.Pellentesque sapien magna, egestas non ante et, aliquet euismod dolor.Nullam quis turpis venenatis, tincidunt lectus ultrices, fermentum est.Aenean erat libero, condimentum ut quam eget, ultrices rhoncus ipsum.Nullam eget massa eu ante efficitur elementum et ut mauris.Mauris nec faucibus lorem.Proin egestas urna nibh, eget dapibus tortor condimentum quis.Nunc vitae interdum nulla.Donec quis nunc nibh.

Vivamus at elementum arcu.Integer mauris turpis, auctor et vulputate ac, blandit id elit.Interdum et malesuada fames ac ante ipsum primis in faucibus.Nunc efficitur pharetra aliquam.Praesent dapibus ut eros eu pulvinar.Phasellus feugiat mi massa.Ut eu aliquam neque.Proin sit amet tincidunt tellus, in molestie metus.Nam condimentum metus metus, vitae tempus erat dictum ut.In a rhoncus lectus, consectetur interdum leo.`,
      bg: "#0000ff",
      img: "img/quote-bg-1.jpg"
    },
  ];


  const [bg, setBG] = useState("img/quote-bg-1.jpg");

  

  return (

    <div
      style={{
        position: "relative",
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(${bg})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        transition: "all 0.5s ease"
      }}
    >

      {
        sections.map((section, i) => {
          return (
            <Wrap key={"asdf"+i} className="text-white">
              <Section setBg={setBG} bg={section.img}>
                <Container>
                  <motion.div>
                    {section.text}
                  </motion.div>
                </Container>
              </Section>
            </Wrap>
          )
        })
      }

    </div>
    // <QuoteRevealWrapper
    //   style={{backgroundImage: `url("${background}")`}}
    // >
    //   <BlockQuoteWrapper>
    //     <BlockQuoteBG
    //       className="bg-gray"
    //       variants={ScaleInFromTop}
    //       initial="start"
    //       animate={quoteIsInView && "end"}
    //     />
    //     <BlockQuoteBorder />
    //     <Container fluid>
    //       <Row>
    //         <Col lg={{offset: 1, span: 8}} xs={{offset: 1, span: 10}}>
    //           <BlockQuote
    //             ref={quoteRef}
    //           >
    //             <p className="text-white">{text}</p>
    //             <footer
    //               className="text-orange"
    //             >{attribution}</footer>
    //           </BlockQuote>
    //         </Col>
    //       </Row>
    //     </Container>
    //   </BlockQuoteWrapper>

    // </QuoteRevealWrapper>
  );

}

export default Test;