
import { Container, Row, Col } from "react-bootstrap";
import { Weanlings, Deadlines } from "../../constants";
import Hero from "../Hero";
import QuoteReveal from "../QuoteReveal";
import ParallaxSection from "../ParallaxSection";
import ImageGallery from "../ImageGallery";
import GenericContentContainer from "../GenericContentContainer";

const Results = () => {



  return (
    <div>

      <div style={{ top: "7rem", position: "relative" }}>
        <div style={{
          position: "relative",
          paddingTop: "max(60%, 326px)",
          height: "0",
          width: "100%"
        }}>
          <iframe
            allow="clipboard-write"
            sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
            allowfullscreen="true"
            style={{
              position: "absolute",
              border: "none",
              width: "100%",
              height: "100%",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }}
            src="https://e.issuu.com/embed.html?d=nov24_-_mailer-final&u=keeneland" />
        </div>
        {/* <div style="position:relative;padding-top:max(60%,326px);height:0;width:100%"><iframe allow="clipboard-write" sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms" allowfullscreen="true" style="position:absolute;border:none;width:100%;height:100%;left:0;right:0;top:0;bottom:0;" src="https://e.issuu.com/embed.html?d=nov24_-_mailer-final&u=keeneland"></iframe></div> */}
      </div>

      {/* <Hero
        background={"img/hero-bg-2.jpg"}
        title={["Find your market here"]}
        subtitle={"A global buying bench 1,000 buyers deep. Top dollar for your weanling. International demand for your mare. It's all waiting for you here."}
        animated={false}
      />

      <ParallaxSection>
        <Container fluid className="text-white">
          <Row className="justify-content-center px-3">
            <Col lg={10}>
              <Row>
                <Col lg={9}>
                  <h2>The top 10 mares were bought by 9 differernt buyers in 2023 with prices like...</h2>
                  <hr />
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6} lg={4} className="my-5 pe-5">
                  <h3>$2.9 million<br />Puca</h3>
                </Col>
                <Col xs={12} md={6} lg={4} className="my-5 pe-5">
                  <h3>$2.1 million<br />White Hot</h3>
                </Col>
                <Col xs={12} md={6} lg={4} className="my-5 pe-5">
                  <h3>$1.8 million<br />Skims (GB)</h3>
                </Col>
                <Col xs={12} md={6} lg={4} className="my-5 pe-5">
                  <h3>$1.8 million<br />Ack Naughty</h3>
                </Col>
                <Col xs={12} md={6} lg={4} className="my-5 pe-5">
                  <h3>$1.7 million<br />Three Witches</h3>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </ParallaxSection>

      <div className="bg-beige">
        <GenericContentContainer>

          <h2 className="my-5">Keeneland November has sold the top weanling in North America 12 of the last 13 years, and had North America’s top 10 weanling prices in 2023, including: </h2>
          <Row>
            <Col lg={7}>
              <Row className="justify-content-between">
                {
                  Weanlings.map((weanling, i) => {
                    return (
                      <Col xs={12} md={6} className="my-4 pe-5" key={"deadline-data-" + i}>
                        <h3 className="my-0">{weanling.amount}</h3>
                        <p><strong dangerouslySetInnerHTML={{ __html: weanling.content }}></strong></p>
                      </Col>
                    )
                  })
                }
              </Row>
            </Col>
            <Col className="d-lg-none" xs={12}>
              <hr />
            </Col>
            <Col lg={5} className="border-lft-lg align-self-start">
              <p className="ps-lg-5"><span className="h3 d-block">10 weanlings sold for</span><span className="h2 text-orange d-block">$500,000+</span><strong>compared to 5 the year prior</strong></p>
              <p className="ps-lg-5"><span className="h3 d-block">19 weanlings sold for</span><span className="h2 text-orange d-block">$400,000+</span><strong>compared to 10 the year prior</strong></p>
            </Col>
          </Row>

        </GenericContentContainer>
      </div>

      <QuoteReveal
        text={"We’ve had a lot of success here at Keeneland November selling babies - it’s just a great market. It seems like this year he had all the good pinhookers, he had a couple of end users. But the pinhookers have really, really stepped it up in the last couple of years where they’re comfortable paying four or five hundred for a horse."}
        attribution={"Griffin Mayer on the weanling market"}
        background={"img/quote-bg-2.jpg"}
      />


      <QuoteReveal
        text={"Each year dozens of the most prominent Japanese breeders converge on Lexington to snap up the best broodmares in the sale. Those that are famous around the world but also those unsung heroes who have great success domestically but might not have their progeny taken on the road. Regardless of the size of the breeding farm in Japan, going to Keeneland November is an essential part of building a successful breeding operation in Japan."}
        attribution={"Kate Hunter"}
        background={"img/quote-bg-3.jpg"}
      /> */}
    </div>
  );
}

export default Results;