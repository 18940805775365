import { motion } from "framer-motion"
import styled from 'styled-components';

// Styled components
export const SliderWrapper = styled.div`
  max-width: 100vw;
  overflow: hidden !important;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40.25vw;
  //border: 1px solid #f00;
    img {
    transition-property: transform;
    transition: all 1.5s;
    transition-timing-function: ease-in-out;

   }

   img {
    max-width:100%;
    overflow:hidden;
    transition-property: transform;
    transition: all 1.5s;
    transition-timing-function: ease-in-out;
    padding:0;
    margin:0;
   }
    // the image zoom effect is just pure css because nested framer motion was acting weird/not working
  .sactive img {
    transform: scale(1.0);
    
  }
  .inactive img {
    
    transform: scale(1.2);
  }

  @media (max-width: 960px) {
    height: 30vh;
  }

`;

export const SlidesContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  width:fit-content;
  will-change: transform, left; // Optimizes animations
  svg {
    transition: 0.6s;
    position:relative;
    left:0;
  }
  .true svg {
   
    left:20px;
    position:relative;
  
  }
  
  position:absolute;
  ${(props) => (props.mode === 1 ? "left:-5vw;" : "left:-108vw;")}
  


`;

export const Slide = styled(motion.div)`

${(props) => (props.mode === 1 ? `
  height: 40%;
  width: 33%;
  min-width: 33vw;
  max-width: 33vw;
  margin-right: 2vw;
  ` : `
  /*height: 61%;
  width: 60%;
  min-width: 60vw;
  max-width: 60vw;*/
  width:60vw;
  margin-right: 5vw;
`)
  }

flex-shrink: 0; 
position: relative; 
overflow:hidden;
display: flex;
justify-content: center;
align-items: center;
transform: scale(1.0);
img {
  top:0;
  left:0;
  right:0;
  bottom:0;

}


  

`;

export const Caption = styled.div`

  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,0.8071603641456583) 0%, rgba(0,0,0,0.4962359943977591) 66%, rgba(255,255,255,0) 100%);
   font-family: Eastman;
   text-transform: uppercase;
   font-size:40px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  width:100%;
  padding: 0 1rem 10px;
  .spaaaaaaace {
    font-family: Open Sans;
    line-height: 27px;
    letter-spacing: 8px;
    font-weight:normal;
    text-transform: uppercase;
    font-size:18px;
  }

  @media (max-width: 960px) {
    font-size: 14px;
    line-height:15px;
    .spaaaaaaace {
      font-size: 12px;
      line-height:16px;
      letter-spacing: 6px;
    }
    
  }
  @media (max-width: 720px) {
    .spaaaaaaace {
      font-size: 10px;
      line-height:14px;
    }
    
  }


`;

export const NavArrow = styled(motion.div)`
  position: absolute;
  top: 0;
  color: white; // < > symbols 
  font-size: 1.5em; // control symbol size 
  line-height: 1; // control symbol placement
  width: 50px; // symbol circle diameter
  height: 50px; // symbol circle diameter
  border-radius: 50%; 
  border: none; 
  display: flex;
  justify-content: center; 
  align-items: center; 
  cursor: pointer; 
  outline: none; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
  background: #777;



  ${(props) => (props.direction === 'left' ? 'left: 20px;' : 'right: 20px;')}
  
  cursor: pointer;
  button {
    width: 18px;
    ${(props) => (props.direction === 'left' ? 'transform: rotate(-135deg);' : 'transform: rotate(45deg);')}
  
  }
  button {
    background: none;
    border: none;
  }
  button:before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    border-color: #fff;
    box-sizing: border-box;
    content: "";
    display: inline-block;
    height: 100%;
    position: relative;
    vertical-align: top;
    width: 20px;
    left:-10px;
    top:7px;
    height:20px;
    color: #000;
  }
  .is-sr-only {
    display:none;
  }
  z-index: 1000;



`;

export const NavWrapper = styled.div`

    height: 25.46px;

    opacity: 1;
    position: relative;
    // top: 16.875vw;
    bottom: -6%;
    margin:0 auto;
    padding-bottom: 8%;
    /*transform: translate(-50%, -50%);*/
    transition: opacity .1s;
    max-width:20vw;
    

    z-index: 20;
    color:#000;
    
    

    @media (max-width: 960px) {
        position:relative;
        max-width:55vw;

        margin:0 auto;
        padding-bottom:50vh;
        

      }


`;

export const NavCount = styled.div`
  position: absolute;
  top: 0px;
  font-family: Open Sans;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0.02em;
  text-align: center;
  margin-top:0;
  color: #000;


  left:0;
  right:0;
`;