import { styled } from "styled-components";
import { motion } from "framer-motion";

export const QuoteRevealWrapper = styled.section`
  min-height: 150vh;
  display: flex;
  align-items: top;
  justify-content: center;
  /* background-position: right top; */
  background-position: center;
  background-size: cover;
  padding-bottom: 20vh;
  position: relative;
  overflow: hidden;

  @media (orientation: portrait) {
    min-height: 120vh;
  }

`;

export const BlockQuoteWrapper = styled.div`
  padding: 10vh 0;
  position: relative;
  @media (min-width: 992px) {
    padding: 16vh 0;
  }
`;

export const BlockQuoteBG = styled(motion.div)`
  position: absolute;
  width: 180%;
  height: 133%;
  top: 0;
  border-radius: 50%;
  top: -73%;
  left: -60%;
  
  @media (orientation: portrait) and (max-width: 992px) {
    width: 270%;
    top: -70%;
    left: -120%;
  }

  @media (min-width: 992px) {
    top: -66%;
    left: -80%;
  }

`;

export const BlockQuoteBorder = styled.div`
  position: absolute;
  width: calc(180% + 40px);
  height: calc(133% + 40px);
  top: 0;
  border-radius: 50%;
  border: 1px solid #cccccc;
  top: calc(-73% - 20px);
  left: calc(-60% - 20px);

  @media (orientation: portrait) and (max-width: 992px)  {
    width: calc(270% + 40px);
    left: calc(-120% - 20px);
    top: calc(-70% - 20px);
  }

  @media (min-width: 992px) {
    top: calc(-66% - 20px);
    left: calc(-80% - 20px);
  }
`;


export const BlockQuote = styled.blockquote`
  font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;

    p,
    footer {
      font-weight: 900;
      text-transform: uppercase;
      font-size: 2vw;
      line-height: 2vw;
      position: relative;
    }
    p:before {
      content: "“";
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-100%);
    }
    p:after {
      content: "”";
    }
    footer {
      font-size: 1.33vw;
      line-height: 1.33vw;
    }
    footer:before {
      content: "— ";

    }
    @media (max-width: 1920px) {
      p,
      footer {
        font-size: 2.9vw;
        line-height: 2.9vw;
      }
      footer {
        font-size: 1.93333vw;
        line-height: 1.93333vw;
      }
    }
    @media (max-width: 992px) {
      p,
      footer {
        font-size: 4vw;
        line-height: 4vw;
      }
      footer {
        font-size: 2.66vw;
        line-height: 2.66vw;
      }
    }
    @media (max-width: 600px) {
      p,
      footer {
        font-size: 5vw;
        line-height: 5vw;
      }
      footer {
        font-size: 3.5vw;
        line-height: 3.5vw;
      }
    }
`;