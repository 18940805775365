import { useRef, React, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { motion } from "framer-motion";
import Overlay from "../Overlay";
import RotatingText from "../RotatingText";
import { FadeInLeft, FadeInScaleUpRotate, RotateScaleIn, BackgroundRotateScaleIn } from "../../constants";
import { FixedContainer, HeroOuter, HeroWrapper, HeroBackground } from "./styles";





const Hero = ({ text, desktopBackground, mobileBackground = desktopBackground, animated, children }) => {

  const parentRef = useRef(null);

  const [maskSize, setMaskSize] = useState("0px");

  const [wrapperMinHeight, setWrapperMinHeight] = useState(document.documentElement.clientHeight);

  const [rotatingTextInstance, setRotatingTextInstance] = useState(null);

  const onInitComplete = (definition) => {
    // setRotatingTextInstance(rotatingText);
  }

  const resetMaskSize = () => {
    const sideA = document.documentElement.clientHeight / 2;
    const sideB = document.documentElement.clientWidth / 2;
    const radius = Math.ceil(Math.sqrt(Math.pow(sideA, 2) + Math.pow(sideB, 2)) * 2) + 100;
    setMaskSize(radius + "px");
    setWrapperMinHeight(document.documentElement.clientHeight);
  }


  useEffect(() => {
    setRotatingTextInstance(rotatingText);
    resetMaskSize();

    window.addEventListener("resize", resetMaskSize);
    return () => {
      window.removeEventListener("resize", resetMaskSize);
    }

  }, [])

  const rotatingText = <RotatingText text={text} parentRef={parentRef} />;


  return (
    <FixedContainer>
      <HeroOuter
        className="hero-outer"
        // variants={RotateScaleIn}
        // initial="start"
        // animate="end"
        onAnimationComplete={onInitComplete}
      >

        {
          animated ?

            <HeroWrapper
              ref={parentRef}
              // desktopBackground={desktopBackground}
              // mobileBackground={mobileBackground}
              className="hero-wrapper"
              initial="start"
              animate="end"
              style={{ minHeight: wrapperMinHeight + "px" }}
              variants={
                {
                  start: {
                    maskSize: "0px"
                  },
                  end: {
                    maskSize: maskSize,
                    transition: {
                      duration: 2,
                      ease: [.68, .01, .22, .96]
                    }
                  }
                }
              }
            >



              <div
                className="d-portrait-none">
                <HeroBackground
                  x="cover"
                  y="cover"
                  variants={RotateScaleIn}
                  initial="start"
                  animate="end"
                  style={
                    {
                      backgroundImage: `url(${desktopBackground})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }
                  }
                />
              </div>

              <div className="d-landscape-none">
                <HeroBackground
                  x="cover"
                  y="cover"
                  variants={RotateScaleIn}
                  initial="start"
                  animate="end"
                  style={
                    {
                      backgroundImage: `url(${mobileBackground})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }
                  }
                />
              </div>

              <div className="d-landscape-none">
                <Overlay x="cover" y="cover" z={1}
                  style={{
                    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 30%, rgba(0, 0, 0, 0.00) 75%, rgba(0, 0, 0, 0.50) 100%)",
                    backgroundBlendMode: "multiply"
                  }}
                />
              </div>
              <div className="d-portrait-none">
                <Overlay x="cover" y="cover" z={1}
                  style={{ background: "radial-gradient(circle, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 45%, rgba(0,0,0,0.6) 80%)" }}
                />
              </div>


              <Overlay x="cover" y="cover" z={2}>
                <motion.div
                  variants={FadeInScaleUpRotate}
                  initial="hidden"
                  animate="visible"
                >
                  {rotatingTextInstance}
                </motion.div>
              </Overlay>

              {children}

            </HeroWrapper>

            :

            <HeroWrapper
              ref={parentRef}
              desktopBackground={desktopBackground}
              className="text-shadowed hero-wrapper"
            >
              {/* <Overlay x={"center"} y={"center"}>
                <Container>
                  <Row className="justify-content-center">
                    <Col lg={8} className="text-center text-white">
                      <h1>{title}</h1>
                      <p className="h4"><strong><span dangerouslySetInnerHTML={{__html: subtitle}}></span></strong></p>
                    </Col>
                  </Row>
                </Container>
              </Overlay> */}
            </HeroWrapper>

        }
      </HeroOuter>
    </FixedContainer >
  )
}

export default Hero;